import React from "react";
import { Flex } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Button } from "@ryerson/frontend.button";
import { Link } from "@ryerson/frontend.navigation";
import { SelectedProductType } from "./SelectedProduct";
import { displayPrice, getLeadTimeRangeDisplay } from "@ryerson/frontend.common";
import { Popover, PopperExposedHandlers } from "@ryerson/frontend.modal";
import { Icon } from "@ryerson/frontend.assets";
import { DiscountPriceModel } from "@ryerson/frontend.common";
import { emDash } from "@components/Helper/common";
import { navigateToProductDetails } from "@ryerson/frontend.common";
import { getDisplayUom } from "@ryerson/frontend.common";
import { useApplication } from "@ryerson/frontend.application";

const GenericSelectedProductDetail = styled(Flex)`
	margin-left: 5%;
	max-width: 75%;
	${(props: any) => {
		const { breakpoint } = props;
		return css`
			@media only screen and (max-width: ${breakpoint}) {
				margin-left: 3%;
				max-width: 65%;
			}
		`;
	}}
`;

const ImageContainer = styled.img`
	height: 90px;
	width: 90px;
	object-fit: contain;
	border-radius: 2px;
	${(props: any) => {
		const { bgColor } = props;
		return css`
			background: ${bgColor};
		`;
	}};
`;

const SelectedProductContainer = styled(Flex)`
	position: relative;
	margin-top: 10px;
	padding: 30px;
	width: 100%;
	border-radius: 2px;
	${(props: any) => {
		const { color } = props;
		return css`
			background: ${color};
		`;
	}};
`;

const SelectedProductDetailContainer = styled(Flex)`
	position: relative;
	width: 55%;
`;

const Divider = styled.div`
	margin-left: 3%;
	${(props: any) => {
		const { color, dir } = props;
		return dir === "vertical"
			? css`
					border-left: 1px solid ${color};
			  `
			: css`
					border-top: 1px solid ${color};
			  `;
	}};
	opacity: 0.1;
`;

const LinkButtonContainer = styled(Flex)`
	margin-left: 3%;
	width: 45%;
`;

const addDefaultSrc = (event: any) => {
	event.target.src = "/NoImageImage.svg";
};

const fadeIn = css`
	animation: fadeIn linear 1s;
	-webkit-animation: fadeIn linear 1s;
	-moz-animation: fadeIn linear 1s;
	-o-animation: fadeIn linear 1s;
	-ms-animation: fadeIn linear 1s;
	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`;

const MoreYouBuySection = styled(Flex)`
	width: 350px;
	border-radius: 2px;
	box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
		0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725);
	${(props: any) => {
		const { white } = props;
		return css`
			background: ${white};
		`;
	}}
`;

const MoreYouBuyHeader = styled(Flex)`
	padding: 10px;
	border-radius: 2px;
	${(props: any) => {
		const { gray, white } = props;
		return css`
			background: ${gray};
			color: ${white};
		`;
	}}
`;

const MoreYouBuyRowContent = styled(Flex)`
	margin: 10px 10px;
	padding: 0px 10px;
	width: 100%;
`;

const SelectedProductDesktop: React.FC<SelectedProductType> = ({
	isRetrieving,
	priceUom,
	qtyUom,
	convertedQuantity,
	convertedPrice,
	content,
	selectedProduct,
	getQueryString,
	currencyType,
	leadTimeAndDeliveryDate,
	canPriceView,
	filteredDiscountedPrice,
	hasDiscountPrices,
	onProductDetailsClick,
}) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	const { lightGray, lighterGray, white, primaryBrand, darkGray, gray, secondaryBrand } =
		theme.colors.primary;
	const breakPoint = theme.breakpoints.xl;

	const moreYouBuyPopperRef = React.useRef<PopperExposedHandlers | null>(null);

	const getCorrectCase = (word: string) => {
		return word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : "";
	};

	const isZero = convertedPrice === 0;

	const validPriceInfo = convertedPrice.toString() !== "NaN";

	return (
		<SelectedProductContainer {...{ color: darkGray }}>
			<SelectedProductDetailContainer justifyContent="space-between">
				<Flex css={{ width: "70%" }}>
					<ImageContainer
						onError={(event) => addDefaultSrc(event)}
						src={`/static-assets/images/renderings/${getCorrectCase(
							selectedProduct?.category
						)}${getCorrectCase(selectedProduct?.form)}${
							selectedProduct?.shape !== "N/A"
								? getCorrectCase(selectedProduct?.shape)
								: ""
						}.png`}
						{...{ bgColor: lighterGray }}
					/>
					<GenericSelectedProductDetail
						direction="column"
						justifyContent="space-between"
						{...{ breakpoint: breakPoint }}
					>
						<Typography size="lg" color={white} css={{ wordWrap: "break-word" }}>
							{selectedProduct?.fastPath}
						</Typography>
						<Typography color={lightGray} css={{ wordWrap: "break-word" }}>
							{selectedProduct?.altPartNumber?.replace(/<br\/>/gi, "") ||
								selectedProduct?.materialMaster}
						</Typography>
						<Typography color={lightGray} css={{ wordWrap: "break-word" }}>
							{selectedProduct?.description}
						</Typography>
					</GenericSelectedProductDetail>
				</Flex>
				<div css={{ marginLeft: "0", width: "25%" }}>
					<Flex
						direction="column"
						justifyContent="space-between"
						css={{ height: "100%", textAlign: "end" }}
						alignContent="flex-end"
					>
						<Flex justifyContent="flex-end">
							<Typography color={lightGray} size="sm">
								{content.leadTime}:&nbsp;
								{getLeadTimeRangeDisplay(leadTimeAndDeliveryDate, language)}
							</Typography>
						</Flex>
						<Flex
							direction="column"
							alignItems="flex-end"
							css={!isRetrieving ? fadeIn : ""}
						>
							{isZero ? (
								<>
									<Typography color={lightGray} size="sm">
										{content.priceShownInCart}
									</Typography>
								</>
							) : (
								<>
									{canPriceView && (
										<>
											<Typography color={white} size="sm">
												{content.priceAt
													.replace(
														"%updatedQuantity%",
														Number.isNaN(convertedQuantity)
															? emDash
															: convertedQuantity.toString()
													)
													.replace(
														"%qtyUom%",
														getDisplayUom(
															selectedProduct.form,
															qtyUom,
															"quantity"
														)
													)}
											</Typography>
											<Flex alignItems="flex-end">
												<>
													<Typography color={white} size="md">
														{currencyType}&nbsp;
													</Typography>
													<Typography color={white} size="lg">
														{validPriceInfo
															? displayPrice(convertedPrice)
															: emDash}
														&nbsp;/&nbsp;
														{getDisplayUom(
															selectedProduct.form,
															priceUom,
															"price"
														)}
													</Typography>
												</>
											</Flex>
										</>
									)}
								</>
							)}
						</Flex>
					</Flex>
				</div>
			</SelectedProductDetailContainer>
			<Divider color={white} dir="vertical" />
			<LinkButtonContainer justifyContent={hasDiscountPrices ? "space-between" : "flex-end"}>
				{canPriceView && hasDiscountPrices && (
					<Flex direction="column" justifyContent="flex-end" css={{ borderColor: white }}>
						<Popover
							trigger={
								<Typography color={lightGray} size="sm" css={{ cursor: "pointer" }}>
									{content.theMoreYouBuy}
								</Typography>
							}
							offset={[0, 22]}
							popoverType="caret"
							ref={moreYouBuyPopperRef}
						>
							<MoreYouBuySection direction="column" {...{ white }}>
								<Flex
									justifyContent="space-between"
									alignItems="center"
									css={{ margin: "10px" }}
								>
									<Typography
										size="md"
										color={secondaryBrand}
										css={{ fontWeight: 500, width: "90%" }}
									>
										{`${content.theMoreYouBuyHeader}${", "}${
											content.theMoreYouSaveHeader
										}`}
									</Typography>
									<Icon
										icon="close"
										circled={true}
										onClick={() => moreYouBuyPopperRef?.current?.close()}
									/>
								</Flex>
								<Flex
									{...{ lightGray }}
									direction="column"
									css={{ marginBottom: 10 }}
								>
									<MoreYouBuyHeader
										justifyContent="space-between"
										{...{ white, gray }}
										css={{ margin: "0px 10px" }}
									>
										<Typography color={white} size="xs">{`${
											content.qty
										}, ${getDisplayUom(
											selectedProduct.form,
											qtyUom,
											"quantity"
										)}`}</Typography>
										<Typography color={white} size="xs">{`${
											content.price
										}, $ / ${getDisplayUom(
											selectedProduct.form,
											priceUom,
											"price"
										)}`}</Typography>
									</MoreYouBuyHeader>
									{filteredDiscountedPrice.map(
										(row: DiscountPriceModel, index: number) => {
											return (
												<Flex key={index}>
													<MoreYouBuyRowContent
														justifyContent="space-between"
														alignItems="center"
													>
														<Flex alignItems="center">
															<Typography
																color={primaryBrand}
																size="sm"
															>
																{row.end
																	? `${row.start}-${row.end}`
																	: `${row.start}+`}
															</Typography>
														</Flex>
														<Flex>
															<Typography
																color={secondaryBrand}
																size="sm"
															>{`${displayPrice(
																Number(Number(row.price).toFixed(2))
															).substring(1)}`}</Typography>
														</Flex>
													</MoreYouBuyRowContent>
												</Flex>
											);
										}
									)}
								</Flex>
							</MoreYouBuySection>
						</Popover>
						<Divider color={lightGray} css={{ marginLeft: "0%" }} />
					</Flex>
				)}
				<Flex css={{ maxWidth: "33%" }} justifyContent="flex-start">
					<Link
						gatsby={false}
						to={navigateToProductDetails(
							selectedProduct?.id + getQueryString(),
							selectedProduct?.altPartNumber,
							"/store"
						)}
					>
						<Button
							shape="circle"
							label={content.productDetails}
							leftIcon="chevron-right"
							foreground={"light"}
							onClick={onProductDetailsClick}
							background={primaryBrand}
							css={{ whiteSpace: "pre-line" }}
							size="sm"
						/>
					</Link>
				</Flex>
			</LinkButtonContainer>
		</SelectedProductContainer>
	);
};

export default SelectedProductDesktop;
